/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import third party library styles here.
/* ----------------------------------------------------------------------------------------------------- */

/* Perfect scrollbar */
@import "~perfect-scrollbar/css/perfect-scrollbar.css";


@import "~@ng-select/ng-select/themes/default.theme.css";


@import "~ngx-ui-switch/ui-switch.component.css";

@import "~font-awesome-animation/css/font-awesome-animation.min.css";

/* Leaflet */
@import "~leaflet/dist/leaflet.css";

@import "~leaflet-draw/dist/leaflet.draw.css";
@import "~leaflet-styleeditor/dist/css/Leaflet.StyleEditor.min.css";
@import "~leaflet.fullscreen/Control.FullScreen.css";
@import "~leaflet-sidebar-v2/css/leaflet-sidebar.min.css";
@import "~leaflet-responsive-popup/leaflet.responsive.popup.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";



@import "../assets/fonts/impact-label/impact-label.css";
@import "../assets/fonts/inter/inter.css";
@import '~tippy.js/dist/tippy.css';
@import 'ngx-toastr/toastr';
@import '~css-star-rating/scss/star-rating';

.ng-select .ng-select-container {
    min-height: 50px;
}

.ng-select.ng-select-single .ng-select-container {
    height: 50px;
}



.ng-select.mat-field .ng-select-container {
    border-radius: 0 !important;
    border: none !important;
}

.ng-select.mat-field .ng-input {
    top: 16px !important;
}
.ng-select.mat-field.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border: none !important;
    box-shadow: none !important;
}

.default-reader-marker {
    background: url("../assets/images/reader/BPR14ReaderMapPinIcon.png");
    width: 35px;
    height: 35px;
    margin-left: -6px;
    margin-top: -6px;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
}

.fill-remaining-space {
    flex: 1 1 auto;
}

.mat-snack-bar-container {
    color: #fff;
}

.default-snackbar {
    padding: 10 !important;
    background: #d80202; 
}

.success-snackbar {
    padding: 10 !important;
    background-color: #01af10;
}

.err-snackbar {
    padding: 10 !important;
    background-color: #d80202;
}

.info-snackbar {
    padding: 10 !important;
    background: #0076a5;
}

.green-snackbar {
    background: rgb(65, 252, 134);
    color: white;
}

.green-snackbar button {
    background-color: rgb(65, 252, 134);
    color: white;
    border: none;
}

.red-snackbar {
    background: #f44336;
    color: white;
}

.red-snackbar button {
    background-color: #f44336;
    color: white !important;
    border: none;
}

// fix child submenu font size
.fuse-vertical-navigation-item-children {
    .fuse-vertical-navigation-item {
        font-size: 12px !important;
        padding-left: 32px !important;
    }
    .fuse-vertical-navigation-item-icon {
        margin-right: 8px !important;
        font-size: 18px;
    }
}
