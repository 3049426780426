@tailwind components;

@layer components {

    // button
    button.frosty {

        @apply frosty-base;

        &.thin-ice {
            @apply frosty-thin;
        }

        /* Disabled */
        &.mat-button-disabled {
            @apply opacity-40 #{!important};
        }

        /* Color options */
        &.red {
            @apply bg-red-500/70 text-white #{!important};
        }

        &.blue {
            @apply bg-blue-500/70 text-white #{!important};
        }

        &.green {
            @apply bg-green-500/70 text-white #{!important};
        }
        &.white {
            @apply bg-white/90 text-black #{!important};
        }
    }
}