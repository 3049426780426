// // // @import '~@angular/material/theming'; orijinal fuse da böyle birşey yok
// // .mat-button,
// // .mat-flat-button,
// // .mat-raised-button,
// // .mat-stroked-button {
// //    border-radius: 6px !important;
// //    padding-top: .6rem !important;
// //    padding-bottom: .6rem !important;
// //    padding-left: .75rem !important;
// //    padding-right: .75rem !important;

// // }

// /* Fix input element */
// .mat-form-field.mat-form-field-appearance-fill.fuse-mat-dense .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-input-element {
//    padding: 10px 0 !important;
//  }

/* Görünürlük geçişi ve maksimum yüksekliği ayarla */
