* {
    scrollbar-width: thin;
}



// iphone pwa fix
html {
    min-height: calc(100% + env(safe-area-inset-top)) !important;
    overflow: hidden;
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
    margin: 0;
    padding: 0;
    height: 100% !important;
}

.safe-area-p {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left) !important;
}

.safe-area {
   margin: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left) !important;
}

.safe-container {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left) !important;
    width: 100% !important;
    //height: 100% !important;
    margin-top: 4rem !important;
   // margin-top: env(safe-area-inset-top)!important;
    margin-bottom: env(safe-area-inset-bottom)!important;
}


// iphone pwa fix end

body {
    touch-action: manipulation;
}


* {
    scroll-behavior: smooth;
}


.toast-top-right {
    top: 90px;
}

.leaflet-marker-pane {
    z-index: 1025 !important;
}

.map-label {
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    text-shadow: 1px 1px 0 rgb(175, 177, 178), 2px 2px 0 rgba(0, 0, 0, 0.25);
    color: #3f3f3f;
}

.leaflet-container {
    font: unset;
}

.leaflet-popup {
    width: 600px !important;
}

.leaflet-popup-content-wrapper {
    background: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
}

.leaflet-popup-tip-container {
    margin-top: -13px;
    margin-left: 80px;
}

.map-item img {
    height: 100% !important;
    width: 100% !important;
    border: solid;
    border-color: transparent;
    object-fit: cover;
    border-radius: 50% !important;
}

.item-online img {
    border-color: #52ca0d;
    background: rgba(51, 217, 178, 1);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
    animation: pulse-green 2s 3;
}

@keyframes pulse-green {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
    }
}

.item-offline img {
    border-color: rgba(255, 82, 82, 1);
    background: rgba(255, 82, 82, 1);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
}

.default-tooltip {
    background: none;
    border: none;
    box-shadow: none;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.7);
    border: 1px solid rgba(255, 255, 255, 0.6);
    padding: 2px 3px;
}

.disabled-tooltip {
    background: none;
    border: none;
    box-shadow: none;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.7);
    border: 1px solid rgba(255, 255, 255, 0.6);
    padding: 2px 3px;
}


// .frost {
//     /* From https://css.glass */
//     background: rgba(255, 255, 255, 0.48);

//     box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
//     backdrop-filter: blur(14.4px);
//     -webkit-backdrop-filter: blur(14.4px);
//     border: 1px solid rgba(255, 255, 255, 0.3);
// }


// .frost-button {
//     /* From https://css.glass */
//     background: rgba(255, 255, 255, 0.2) !important;
//     box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
//     backdrop-filter: blur(14.4px) !important;
//     -webkit-backdrop-filter: blur(14.4px) !important;
//     border: 1px solid rgba(255, 255, 255, 0.1) !important;
// }

// .frost2 {
//     /* From https://css.glass */
//     background: rgba(255, 255, 255, 0.2) ;

//     box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
//     backdrop-filter: blur(5px);
//     -webkit-backdrop-filter: blur(5px);
//     border: 1px solid rgba(255, 255, 255, 0.3);
// }

// .frost3 {
//     /* From https://css.glass */
//     background: rgba(255, 255, 255, 0.2) ;
//     box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
//     backdrop-filter: blur(12.9px);
//     -webkit-backdrop-filter: blur(12.9px);
//     border: 1px solid rgba(255, 255, 255, 0.3);
// }

.toast-container {
    z-index: 1000 !important;
    position: relative !important;
}

.fade-in {
    opacity: 0;
    animation: fadeIn 3s ease-in-out forwards;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}


.smooth-transition {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.6s ease-in-out forwards;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}