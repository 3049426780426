@tailwind components;
@import 'base';
@layer components {

    // panel
    div.frosty {

     @apply frosty-base rounded-xl #{!important};
      

        &.thin-ice {
           @apply frosty-thin  #{!important};
        }

        &.thick-ice {
            @apply frosty-thick  #{!important};
         }
    }
}