@import "../assets/styles/theme/dx.material.custom-scheme/dx.material.custom-scheme.css";

/********* PivotGrid *********/

//transparent pivot grid
.dx-pivotgrid {
    background-color: transparent !important;
}

/********* DataGrid *********/

//transparent grid
.dx-datagrid {
    color: rgb(17, 24, 39) !important;
    background-color: transparent !important;
}

// group row background color
.dx-datagrid .dx-group-row {
    // background-color: #4f54551c !important;
    background-color: #FFFFFF1C;
    color: #393838 !important;
}

.dx-datagrid .dx-datagrid-header-panel {
    padding-top: 4px;
    ;
}

// Fix Header Row Background Color
.dx-datagrid-headers {
    background-color: rgba(255, 255, 255, 0.278) !important;
}

// Focused Row Background Color
.dx-datagrid-rowsview .dx-row-focused.dx-data-row>td:not(.dx-focused) {
    border: none;
    color: rgb(255, 255, 255) !important;
    background-color: transparent;
}



.dx-datagrid .dx-datagrid-table .dx-header-row>td {
    padding-top: 12px;
    padding-bottom: 12px;
}

// DevExpress DataGrid Column Header  Font Fix
tr.dx-header-row>td[role="columnheader"]>div.dx-datagrid-text-content {
    font-size: 15px;
    font-weight: 600;
    // color: rgb(30, 41, 59);
}

// DevExpress DataGrid Strong Row Fix
td[role="gridcell"] strong {
    font-weight: 600;
}

// Fix Row Height
.dx-datagrid .dx-row>td {
    padding-top: 6px;
    padding-bottom: 6px;
}


/* Fix Row Border */
.dx-datagrid-rowsview .dx-row {
    border-top: 1px solid #68606010;
    border-bottom: 1px solid #68606010;
}

/* Fix Row Border */
.dx-datagrid .dx-row-lines>td {
    border-bottom: 1px solid #68606010;
}

/* Fix Column Border */
.dx-datagrid .dx-column-lines>td {
    border-left: 1px solid #68606010;
    border-right: 1px solid #68606010;
}

// Fix Focus Background Color
.dx-datagrid-rowsview .dx-row-focused.dx-data-row {
    // Odaklanmış satır için mavi arka plan rengi
    background-color: rgb(56, 189, 248) !important;

    // İç içe seçiciler için varsayılan (transparent) arka plan rengi
    .dx-command-edit .dx-link,
    >td {
        background-color: transparent !important;
    }

    // Odaklanıldığında .dx-command-edit .dx-link ve td'ler için mavi arka plan rengi
    &:focus {

        .dx-command-edit .dx-link,
        >td:not(.dx-focused):not(.dx-cell-modified) {
            background-color: rgb(56, 189, 248) !important;
        }
    }
}

// fix table layout :horizontal bar when opened material drawer
.dx-datagrid-table.dx-datagrid-table-fixed {
 //   table-layout:auto !important;
}

// table.dx-datagrid-table {
//     border-collapse: separate !important;
//     border-spacing: 0 !important;
//   }


// Fix Text Editors

.dx-texteditor {
    border: none;
    border-radius: 8px;

    &.dx-editor-filled::after {
        border: none;
    }

    &.dx-state-active::before,
    &.dx-state-focused::before {
        border-bottom: 1px solid rgba(142, 140, 149, 0.8);
    }
}

.dx-row.dx-data-row.dx-row-lines.dx-column-lines {
    background-color: rgba(255, 255, 255, 0.7);
}

@keyframes dx-datagrid-highlight-change {
    from {
        background-color: #b2b2b200;
    }
    50% {
        background-color: #FFDE64;
    }
}
.dx-datagrid-cell-updated-animation {
    animation: dx-datagrid-highlight-change 1s;
}
.dx-datagrid-row-inserted-animation {
    animation: dx-datagrid-highlight-change 1s;
}