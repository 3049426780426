@import 'base';

@tailwind components;

@layer components {

    // input
    mat-form-field.frosty {

        &.mat-form-field-appearance-fill {
            .mat-form-field-wrapper {
                .mat-form-field-flex {
                    @apply frosty-base #{!important};

                    min-height: 40px !important;

                    .mat-form-field-infix {
                        display: block;
                        .mat-input-element {
                            padding: 10px !important; 
                        }
                    }

                }

            }

            &.thin-ice {
                .mat-form-field-wrapper {
                    .mat-form-field-flex {
                        @apply frosty-thin #{!important};
                    }
                }
            }

            /* Disabled */
            &.mat-form-field-disabled {
                @apply opacity-10 #{!important}; //doesn't work some how
            }


            /* Invalid */
            &.mat-form-field-invalid {

                .mat-form-field-wrapper {

                    .mat-form-field-flex {
                        //Border
                        @apply ring-red-500/50 ring-2 #{'!important'};
                    }
                }

                &.thin-ice {
                    .mat-form-field-wrapper {

                        .mat-form-field-flex {
                            //Border
                            @apply ring-red-500/50 ring-2 #{'!important'};
                        }
                    }
                }
            }

            /* Focused */
            &.mat-focused {

                .mat-form-field-wrapper {


                    .mat-form-field-flex {

                        //Background
                        @apply bg-white/80 #{!important};
                        @apply dark:bg-black/80 #{!important};

                        //Border
                        @apply ring-sky-500 ring-2 #{!important};
                        @apply dark:ring-yellow-500 #{!important};

                    }
                }

                &.thin-ice {
                    .mat-form-field-wrapper {
                        .mat-form-field-flex {

                            //Background
                            @apply bg-white/40 #{!important};
                            @apply dark:bg-black/10 #{!important};
                        }
                    }
                }
            }

            /* Focused and valid fields */
            &.mat-focused:not(.mat-form-field-invalid) {
                .mat-form-field-wrapper {


                    .mat-form-field-flex {}
                }
            }
        }
    }
}