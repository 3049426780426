@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    @keyframes bokehFloat {
        0% {
            opacity: 0;
            transform: translateY(0) scale(0);
        }

        50% {
            opacity: 1;
            transform: translateY(-30vh) scale(1);
        }

        100% {
            opacity: 0;
            transform: translateY(-60vh) scale(0);
        }
    }

    .frosty-base {
       
       // overflow: hidden; //mat-field lerdeli labeli patlatıyor o yüzden kapatıldı

        // Background
        @apply bg-white/60 backdrop-blur-sm shadow-none rounded-md #{!important};
        @apply dark:bg-black/60 #{!important};

        // Border
        @apply border-none ring-2 ring-white/30 #{!important};
        @apply dark:ring-white/10 #{!important};

        // Transition
        @apply transition-all duration-700 #{!important};

        // Hover state
        @apply hover:backdrop-blur-xl hover:bg-white/80 hover:backdrop-contrast-150  hover:backdrop-saturate-50 #{!important};
    }

    .frosty-base.bokeh::before,
    .frosty-base.bokeh::after {
        content: '';
        position: absolute;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.2);
        pointer-events: none;
        mix-blend-mode: lighten;
        filter: blur(8px);
        box-shadow: 0 0 15px 5px rgba(255, 255, 255, 0.3);
        animation: bokehFloat 15s infinite;
    }

    .frosty-base.bokeh::before {
        width: 50px;
        height: 50px;
        top: 20%;
        left: 40%;
        animation-delay: 0s;
    }

    .frosty-base.bokeh::after {
        width: 70px;
        height: 70px;
        top: 60%;
        left: 50%;
        animation-delay: 7.5s;
    }

    .frosty-base.bokeh .bokeh-1::before,
    .frosty-base.bokeh .bokeh-2::before,
    .frosty-base.bokeh .bokeh-3::before,
    .frosty-base.bokeh .bokeh-4::before,
    .frosty-base.bokeh .bokeh-5::before,
    .frosty-base.bokeh .bokeh-6::before,
    .frosty-base.bokeh .bokeh-7::before,
    .frosty-base.bokeh .bokeh-8::before,
    .frosty-base.bokeh .bokeh-9::before,
    .frosty-base.bokeh .bokeh-10::before,
    .frosty-base.bokeh .bokeh-11::before,
    .frosty-base.bokeh .bokeh-12::before,
    .frosty-base.bokeh .bokeh-13::before,
    .frosty-base.bokeh .bokeh-14::before,
    .frosty-base.bokeh .bokeh-15::before,
    .frosty-base.bokeh .bokeh-16::before,
    .frosty-base.bokeh .bokeh-17::before,
    .frosty-base.bokeh .bokeh-18::before,
    .frosty-base.bokeh .bokeh-19::before,
    .frosty-base.bokeh .bokeh-20::before {
        content: '';
        position: absolute;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.2);
        pointer-events: none;
        mix-blend-mode: lighten;
        filter: blur(8px);
        box-shadow: 0 0 15px 5px rgba(255, 255, 255, 0.3);
        animation: bokehFloat 15s infinite;
    }

    .frosty-base.bokeh .bokeh-1::before {
        width: 20px;
        height: 20px;
        top: 10%;
        left: 30%;
        animation-delay: 2s;
    }

    .frosty-base.bokeh .bokeh-2::before {
        width: 40px;
        height: 40px;
        top: 25%;
        left: 60%;
        animation-delay: 4s;
    }

    .frosty-base.bokeh .bokeh-3::before {
        width: 60px;
        height: 60px;
        top: 40%;
        left: 20%;
        animation-delay: 6s;
    }

    .frosty-base.bokeh .bokeh-4::before {
        width: 80px;
        height: 80px;
        top: 55%;
        left: 70%;
        animation-delay: 8s;
    }

    .frosty-base.bokeh .bokeh-5::before {
        width: 100px;
        height: 100px;
        top: 70%;
        left: 40%;
        animation-delay: 10s;
    }

    .frosty-base.bokeh .bokeh-6::before {
        width: 25px;
        height: 25px;
        top: 85%;
        left: 50%;
        animation-delay: 12s;
    }

    .frosty-base.bokeh .bokeh-7::before {
        width: 45px;
        height: 45px;
        top: 15%;
        left: 80%;
        animation-delay: 14s;
    }

    .frosty-base.bokeh .bokeh-8::before {
        width: 65px;
        height: 65px;
        top: 30%;
        left: 10%;
        animation-delay: 16s;
    }

    .frosty-base.bokeh .bokeh-9::before {
        width: 85px;
        height: 85px;
        top: 45%;
        left: 50%;
        animation-delay: 18s;
    }

    .frosty-base.bokeh .bokeh-10::before {
        width: 15px;
        height: 15px;
        top: 60%;
        left: 90%;
        animation-delay: 20s;
    }

    .frosty-base.bokeh .bokeh-11::before {
        width: 35px;
        height: 35px;
        top: 75%;
        left: 20%;
        animation-delay: 22s;
    }

    .frosty-base.bokeh .bokeh-12::before {
        width: 55px;
        height: 55px;
        top: 20%;
        left: 40%;
        animation-delay: 24s;
    }

    .frosty-base.bokeh .bokeh-13::before {
        width: 75px;
        height: 75px;
        top: 35%;
        left: 60%;
        animation-delay: 26s;
    }

    .frosty-base.bokeh .bokeh-14::before {
        width: 95px;
        height: 95px;
        top: 50%;
        left: 80%;
        animation-delay: 28s;
    }

    .frosty-base.bokeh .bokeh-15::before {
        width: 20px;
        height: 20px;
        top: 65%;
        left: 10%;
        animation-delay: 30s;
    }

    .frosty-base.bokeh .bokeh-16::before {
        width: 40px;
        height: 40px;
        top: 80%;
        left: 30%;
        animation-delay: 32s;
    }

    .frosty-base.bokeh .bokeh-17::before {
        width: 60px;
        height: 60px;
        top: 10%;
        left: 70%;
        animation-delay: 34s;
    }

    .frosty-base.bokeh .bokeh-18::before {
        width: 80px;
        height: 80px;
        top: 25%;
        left: 50%;
        animation-delay: 36s;
    }

    .frosty-base.bokeh .bokeh-19::before {
        width: 100px;
        height: 100px;
        top: 40%;
        left: 20%;
        animation-delay: 38s;
    }

    .frosty-base.bokeh .bokeh-20::before {
        width: 25px;
        height: 25px;
        top: 55%;
        left: 70%;
        animation-delay: 40s;
    }

    .frosty-thin {
        // Background
        @apply bg-white/30 backdrop-blur-0 #{!important};
        @apply dark:bg-white/5 #{!important};

        // Border
        @apply ring-white/50 #{!important};
        @apply dark:ring-white/10 #{!important};

        // Transition
        @apply transition-all duration-300 #{!important};

        // Hover state
        @apply hover:backdrop-blur-xl hover:brightness-125 #{!important};
    }

    .frosty-thick {
        @apply bg-white/80 #{!important};
    }

    .frosty-circle {
        @apply rounded-full #{!important};
    }
}