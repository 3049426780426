/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
@import 'tailwind.scss';
@import 'material.scss';
@import 'devexpress.scss';
@import 'vendors.scss';
@import 'custom.scss';

